/* You can add global styles to this file, and also import other style files */

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex.scss';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import '../node_modules/@fullcalendar/daygrid/main.min.css';
@import '../node_modules/@fullcalendar/timegrid/main.min.css';
@import 'assets/demo/flags/flags.css';

.chip-wrap {
	max-height: 150px;
	overflow: auto;
}


body{
	overflow-x: hidden;
}
.migrated {
	color: var(--green-500) !important;
}

:host ::ng-deep .p-chip.custom-chip {
	background: var(--primary-color);
	color: var(--primary-color-text);
	margin: 2px;
	cursor: pointer;
}

:host ::ng-deep .p-chip.custom-chip.group-chip {
	background-color: var(--green-500) !important;
}

.inline-title {
	font-size: 1.2rem;
	display: inline-block;
}

:host ::ng-deep .p-chips-token {
	margin-bottom: 0.1rem !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}
.app-name-footer-wrapper {
	display: flex;
	flex-direction: column;
}

.md-width {
	width: 200px;
}
.lg-width {
	width: 350px;
}
.sm-width {
	width: 40px;
}

.note {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.text-area {
	height: 80px;
	width: 300px;
}
